



























































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component
export default class Features extends Vue {

  features = [
    {
      img: 'https://spics.wantu.cn/3e31ff550854156d18cd64298b97e244.png',
      h5Img: 'https://spics.wantu.cn/909441aa4f6dfb2ec4b2f263227fdd64.png',
      title: '領先行業的電子菜單',
      desc: `· 支持多達100多種的菜品模式
          · 支持茶餐廳、飲品店、西餐套餐、火鍋
          · 支持堂食、外賣自提、外賣配送、驛站取餐
          · 簡單、順暢的手機點餐體驗`
    },
    {
      img: 'https://spics.wantu.cn/e214360e156635d578cec9bb20d784c0.png',
      h5Img: 'https://spics.wantu.cn/b3a9bd4c32e34699148b9f8240bd985b.png',
      title: '餐廳數據一手掌握',
      desc: `· 餐廳營運數據實時展現
          · 訂單、交易額、會員
          · 財務數據統計、分析 
          · 全店智能數據分析，發現趨勢`
    },
    {
      img: 'https://spics.wantu.cn/26bb7cc45df326488acde0a79965203e.png',
      h5Img: 'https://spics.wantu.cn/40cbc02715cabc0bceaec8fb7e6a253c.png',
      title: `建立會員計劃，
          幫你找到VIP`,
      desc: `· 設置會員計劃、電子印花
          · 會員消費智能分析，精準定位核心會員
          · 篩選會員進行精準優惠券推送`
    },
    {
      img: 'https://spics.wantu.cn/575ac56391129c8fac16278bca517cd4.png',
      h5Img: 'https://spics.wantu.cn/9c6be85221a5e10eda12c789c3bbc569.png',
      title: '餐廳管理，隨手搞掂',
      desc: `· 輕鬆完成菜單錄入、餐廳流程設置
          · 手機、pad上輕鬆完成菜品上下架、庫存管理
          · 配合財務製作日結單、財務數據對賬
          · 豐富支付方式可選`
    },
    {
      img: 'https://spics.inctrip.com/06258e8d4c7a233771588b84be98fe35.png',
      // h5Img: 'https://spics.inctrip.com/705c9112a24e6cb2e8db11b83dca3215.png',
      h5Img: 'https://spics.inctrip.com/89a30115791b3d7aaaac2311d5efa1c3.png',
      title: '一部Pos, 管理所有外賣平台訂單',
      desc: `· PinMe已對接Foodpanda、Deliveroo等本港主流外賣平台
          · 外賣平台訂單自動進入PinMe Pos，一部機同時管理所有訂單
          · 通過PinMe後台即可管理各家外賣平台的菜單、菜品、設置價格
          · 自動化的外賣平台的交易可視化圖表、明細報表`
    },
    {
      img: 'https://spics.inctrip.com/1ad6ee7cc7de0f12592a753076e43cf5.png',
      h5Img: 'https://spics.inctrip.com/f6bf9e4687490bea29f1a806c1571ed6.png',
      title: '強大會員系統，提升顧客的忠誠度<br />是餐廳成功的關鍵所在',
      desc: `· 專為香港餐廳設計，提供豐富的會員專屬優惠
          · 支持電子印花、生日優惠、註冊優惠、會員專屬價格
          · 強大的會員數據統計&分析功能
          · 獨立的會員系統，會員歸餐廳擁有及確保會員數據安全`
    }
  ]

  get isH5() {
    return AppModule.isH5
  }
}
